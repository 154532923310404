body {
  color: #ffff;
  background-color: rgb(22, 25, 31);
  font-family: sans-serif;
}

div {
  display: block;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

ul {
  list-style: none;
  padding: 0;
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: -webkit-match-parent;
}

a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

img {
  overflow-clip-margin: content-box;
  overflow: clip;
}

.mirrors {
  margin: 37px auto;
  max-width: 1024px;
}

.mirrors__logo-link {
  margin-bottom: 200px;
}

.logo__picture {
  max-width: 256px;
  max-height: 52px;
}

.mirrors__content {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.mirrors__content-left {
  width: 50%;
  margin-right: 16px;
}

.mirrors__content-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.mirrors__content-title span {
  color: rgb(233, 96, 175);
  margin-right: 4px;
}

.mirrors__content-subtitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.mirrors__aside-desktop {
  border-left: 2px solid #2283f6;
  padding-left: 12px;
  display: block;
}

.mirrors__aside-mobile {
  border-left: 2px solid #2283f6;
  padding-left: 12px;
  display: none;
}

.mirrors_aside-tittle {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}

.mirrors__aside-description {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #93acd3;
}

.domains {
  width: 50%;
  position: relative;

  margin-bottom: 92px;
}

.desktop {
  display: flex;
}

.domains__list-domains {
  width: 100%;
  margin: 0;
  background: rgba(224, 224, 224, 0.04);
  border-radius: 10px;
  padding: 16px;
  box-sizing: border-box;
}

.domains__list-item {
  border-top: 1px solid #202a39;
  padding: 11px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.domains__list-item-left {
  display: flex;
}

.domains__list-title {
  padding-bottom: 8px;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #55657e;
  margin-right: 30px;
}

.domains__list-item-img {
  margin-right: 6px;
}

.domains__list-item-link {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #1d65bb !important;
  text-decoration: none !important;

  cursor: pointer;
}
.domains__list-item-link:hover {
  color: #408ae4 !important;
}

.domains__list-item-online {
  color: #2ddc4b;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.domains__list-item-online-check {
  height: 7px;
  width: 10px;
  margin-right: 8px;
}

.footer-social-icons-desktop {
  display: flex;
}

.footer-social-icons-mobile {
  display: none;
}

.footer-social-icon__icons {
  position: relative;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  text-decoration: none;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;

  cursor: pointer;
}

.footer-social-icons__svg {
  fill: #768aae;
  width: 32px;
  height: 32px;
  margin-bottom: 2px;
  transition: fill 0.3s;
}

@media (max-width: 1024px) {
  .mirrors {
    margin: 24px 16px;
  }

  .mirrors__aside-desktop {
    display: none;
  }

  .mirrors__aside-mobile {
    display: block;

    margin-top: 24px;
  }

  .footer-social-icons-desktop {
    display: none;
  }

  .footer-social-icons-mobile {
    display: flex;
    margin-top: 14px;
  }

  .mirrors__logo-link {
    margin-bottom: 0px;
  }

  .mirrors__content {
    flex-direction: column;
    align-items: flex-start;
  }

  .mirrors__content-left {
    width: 100%;
  }

  .domains {
    width: 100%;
    margin-bottom: 0;
  }
}
